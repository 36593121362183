<template>
  <base-section
    id="features"
    class="secondary"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container
        fluid
        class="hidden-xs-only"
      >
        <v-row>
          <v-col
            v-for="(card, i) in fullCardsV"
            :key="i"
            cols="12"
            sm="1"
            md="1"
            class="py-6"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container
        fluid
        class="hidden-sm-and-up"
      >
        <v-row>
          <v-col
            v-for="(card, i) in fullCardsH"
            :key="i"
            cols="12"
            sm="1"
            md="1"
          >
            <base-info-card
              align="center"
              dark
              v-bind="card"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionFeatures',

    data: () => ({
      cardsV: [
        {
          icon: '',
          title: '',
          text: false,
        },
        {
          icon: 'fas fa-shield-virus',
          title: ' ',
          text: true,
        },
        {
          icon: 'fas fa-plus',
          title: '',
          text: false,
        },
        {
          icon: 'fas fa-qrcode',
          title: ' ',
          text: true,
        },
        {
          icon: '',
          title: '',
          text: false,
        },
        {
          icon: 'fas fa-equals',
          title: '',
          text: false,
        },
        {
          icon: '',
          title: '',
          text: false,
        },
        {
          icon: 'fas fa-check-circle',
          title: ' ',
          text: true,
        },
        {
          icon: '',
          title: '',
          text: false,
        },
        {
          icon: 'fas fa-arrow-right',
          title: '',
          text: false,
        },
        {
          icon: 'fas fa-grin',
          title: ' ',
          text: true,
        },
      ],
      cardsH: [
        {
          icon: '',
          title: '',
          text: false,
        },
        {
          icon: 'fas fa-shield-virus',
          title: ' ',
          text: true,
        },
        {
          icon: 'fas fa-plus',
          title: '',
          text: false,
        },
        {
          icon: 'fas fa-qrcode',
          title: ' ',
          text: true,
        },
        {
          icon: 'fas fa-equals',
          title: '',
          text: false,
        },
        {
          icon: 'fas fa-check-circle',
          title: ' ',
          text: true,
        },
        {
          icon: 'fas fa-arrow-down',
          title: '',
          text: false,
        },
        {
          icon: 'fas fa-grin',
          title: ' ',
          text: true,
        },
      ],
    }),
    computed: {
      fullCardsV () {
        let j = 1
        return this.cardsV.map((card) => {
          card.text = (card.text) ? this.$i18n.t(`V.s${j++}`) : ''
          return card
        })
      },
      fullCardsH () {
        let i = 1
        return this.cardsH.map((card) => {
          card.text = (card.text) ? this.$i18n.t(`H.s${i++}`) : ''
          return card
        })
      },
    },
  }
</script>
<i18n>
{
	"en": {
		"H": {
			"s1": "Certified sanitisation",
			"s2": "Trasparency in the intervention execution",
			"s3": "Safety in the sanitisation",
			"s4": "Relax and serenity"
		},
		"V": {
			"s1": "Certified sanitisation",
			"s2": "Trasparency in the intervention execution",
			"s3": "Safety in the sanitisation",
			"s4": "Relax and serenity"
		}
	},
	"it": {
		"H": {
			"s1": "Sanificazione certificata",
			"s2": "Trasparenza nell'esecuzione dell'intervento",
			"s3": "Sicurezza della sanificazione",
			"s4": "Relax in serenità"
		},
		"V": {
			"s1": "Sanificazione certificata",
			"s2": "Trasparenza nell'esecuzione dell'intervento",
			"s3": "Sicurezza della sanificazione",
			"s4": "Relax in serenità"
		}
	}
}
</i18n>
